import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Provinces from '../containers/provinces';
import Questions from '../containers/questions';

import { setProvince, setHeaderVisibility } from '../actions/questions' 



class Quiz extends Component {

	componentDidMount() {

		const { province } = this.props.match.params;
		const { list } = this.props.quiz;

		if(province && !this.props.quiz.province) {
			this.props.actions.setProvince(list.filter((item,i) => item.slug === province)[0], true);
			setTimeout(() => {
				this.props.history.push('/');
			}, 500)
		}

		//Set header visible
		this.props.actions.setHeaderVisibility(true)

		

	}

	render() {
		const { params } = this.props.match;
		// const { province } = this.props.quiz;	
		let view;

		if(params.question) {
			view = <Questions />
		}  else {
			view = <Provinces />
		}

		return (
			
			<div className="pagewrap">
				<div className="container">
					{view}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
  return {
    quiz: state.quiz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setProvince, setHeaderVisibility }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quiz)