import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'; 
import { answerQuestion, setHeaderLoading } from '../actions/questions';
import Popup from '../components/Popup';
import CheckAnimation from "../lottie/Check.js";
class Questions extends Component {

	constructor() {
		super();
		this.state = {
			popup: {
				open: false,
				content: {}
			}
		}
		this.checkBox = React.createRef();
		this.submit = this.submit.bind(this);
	}


	submit() {
		const { slug, options } = this.props.quiz.province;
		const nextQuestion = options.filter((item, i) => !item.answer)[0];
		
		if(nextQuestion) {
			this.props.history.push(`/kiezen/${slug}/${nextQuestion.slug}`)
		} else {
			this.props.actions.setHeaderLoading(true);
			this.props.history.push(`/themas`);
		}
		
	}
	
	handleChange(question, answer, index, event) {
		this.props.actions.answerQuestion(question, answer);
		setTimeout(() => {
			document.getElementsByClassName('pagewrap')[0].classList.add('activo')
		}, 500)
		setTimeout(() => {
			this.props.actions.setHeaderLoading(false);
			this.submit();
		}, 1050)
	}
	
	closePopup() {
		this.setState({
			popup: {
				open: false,
				content: {}
			}
		})
	}

	openPopup(content, e) {
		this.setState({
			popup: {
				open: true,
				content: content.content
			}
		})
	}

	
	render() {
		
		const { question } = this.props.match.params;
		const { province, answeredQuestions } = this.props.quiz;

		if(!province) return false;

		const questions = province.options;
		const currentQuestion = questions.filter((item, i) => item.slug === question)[0];

		const $answers = currentQuestion.options.map((item, i) => {

			const isAnswer = item.id === currentQuestion.storedAnswerId;
			
			return (
				<div 
					className={`c-radio ${!item.result.length ? 'no-agreement' : ''}`} 
					key={`question-${currentQuestion.id}-answer-${item.id}`}>
					<input
						onChange={this.handleChange.bind(this, currentQuestion, item, i)} 
						id={`question-${item.id}`} 
						type="radio"
						name="question"
						/>
					<label className={ isAnswer ? 'answered':''} htmlFor={`question-${item.id}`}>
						<span className="toggle">
							<CheckAnimation isAnswered={isAnswer} className={`checker-${item.id}`} ref={this.checkBox} />
						</span>
						<span className={`c-question-answer-text`}>{item.title}</span>
					</label>
				</div>
			)
		})

		const { popup } = this.state;
		const $showInfo = (currentQuestion.content.body || currentQuestion.content.video) ? <span onClick={this.openPopup.bind(this, currentQuestion)} className={`c-question__moreinfo`} /> : '';
		const $popup = popup.open ? <Popup content={popup} close={this.closePopup.bind(this)} /> : false;

		return (
			<div ref={(slide) => this.slide = slide} className={`c-question page`}>
				
				<div ref={(title) => this.title = title } >
					<div className="c-question__head">
						<div className={`c-question__head__inner`}>
							<h1 className={`c-main-title c-main-title--alt c-main-title--sub`}>{currentQuestion.title}</h1>
							{$showInfo}
						</div>
						
					</div>
					<span className={`c-question__description`}>{currentQuestion.subTitle}</span>
				</div>
				<div ref={(items) => this.items = items }  className={`c-group c-group--alt`}>
					{$answers}
				</div>
				
				{$popup}
				<div className={`c-theme-label`}>{answeredQuestions+1} / {questions.length}</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
  return {
    quiz: state.quiz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ answerQuestion, setHeaderLoading }, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questions))