import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios';
import Footer from '../components/Footer';
import Chart from '../containers/chart'
import Popup from '../components/Popup'
import PartyDescriptions from '../components/PartyDescriptions'
import StaticContent from '../components/StaticContent'
import { finishTest, setHeaderVisibility, setHeaderLoading } from '../actions/questions'

import { toSlug } from '../utils';

import ResultAnimation from "../lottie/ResultAnimation.js";

const API_ENDPOINT_POST = process.env.REACT_APP_API_ENDPONT + '/api/result';
const MAX_PARTIES = 5;

class Result extends Component {

	constructor() {
		super();
		this.state = {
			isLoading: true,
			popup: {
				open: false,
				content: {}
			},
			allThemes: false,
			noAgreement: false,
		}
		this.showAll = this.showAll.bind(this)
		this.hideAll = this.hideAll.bind(this)
		const header = document.getElementsByClassName('header')[0]
        if(header) {
            header.classList.remove('activo')
        }
	}

	componentDidMount() {
		this.props.actions.setHeaderVisibility(false);
		this.props.actions.setHeaderLoading(false);
		if(!this.props.quiz.province) {
			this.props.history.push('/');
			return;
		}

		document.body.classList.add('c-result-body')

		const { testFinished } = this.props.quiz;
		
		if(!testFinished) {

			const ANSWERS = this.props.quiz.province.options.map(o => {
				return o.storedAnswerId;
			})

			//Build object for API endpoint		
			axios.post(API_ENDPOINT_POST, {
				'info': {
					...this.props.quiz.user
				},
				'province': {
					'id': this.props.quiz.province.id,
					'name': this.props.quiz.province.title
				},
				// 'answers': this.props.quiz.province.options,
				'answers': ANSWERS,
				'selectedThemes': this.props.quiz.themes
			}, {
				headers: {'X-API-KEY': process.env.REACT_APP_API_KEY}
			});
			this.props.actions.finishTest();
		}
	}

	componentWillUnmount() {
		document.body.classList.remove('c-result-body')
	}

	closePopup() {
		this.setState({
			popup: {
				open: false,
				content: {}
			}
		})
	}

	openPopup(content, e) {
		
		this.setState({
			popup: {
				open: true,
				content: content
			}
		})
	}
	

	getThemes(remaining=false) {
		const { province, themes  } = this.props.quiz
		let $list;
		return province.options.map((item, i) => {
			if(!item.answer) return false;

			const { id, title, answer } = item;
			if(themes.includes(id) === remaining) return false;


			const { result } = answer;
			const parties = result;

			

			const miscParties = item.options.filter((miscRes, j) => {
				return miscRes.id !== answer.id && miscRes.result.length
			})
		
			if(result.length >= MAX_PARTIES) {
				$list = <li className={''}>{result.length} partijen</li>
			} else if(result.length === 0) {
				$list = <li className={`c-result__star-wrapper`}><span className="c-result__star">*</span></li>

			} else {
				$list = result.map((res, j) => (
					<li key={res.id} className={`t-${toSlug(res.title)}`}>
						{res.title}
					</li>
				))
			}

			return (
				<div 
					onClick={this.openPopup.bind(this, {
						title: title,
						body: <PartyDescriptions theme={item} parties={parties} miscParties={miscParties} />
					})} 
					className={`c-result__item`} 
					key={`res-${id}`}
				>
					<span>{title}</span>
					<ul>
						{$list}
					</ul>
				</div>
			)
		})
	}

	showAll(e) {
		
		this.setState({
			allThemes: true
		})
	}

	hideAll(e) {
		this.setState({
			allThemes: false
		})
	}

	loadingComplete() {
		this.setState({
			isLoading: false
		}, () => {
			this.props.actions.setHeaderVisibility(true);
		})
	}

	render() {
		const { province } = this.props.quiz

		if(!province) {
			this.props.history.push(`/`)
			return false;
		}

		const $listChosen = this.getThemes();
		const listRemaining = this.getThemes(true);

		const { popup, allThemes } = this.state;
		const $popup = popup.open ? <Popup content={popup} close={this.closePopup.bind(this)} /> : false;
		const $showAll = !allThemes ? <button className={`c-btn-add`} onClick={this.showAll}>Toon alle onderwerpen</button> : false
		const $hideAll = allThemes ? <button className={`c-btn-add c-btn-less`} onClick={this.hideAll}>Verberg overige onderwerpen</button> : false

		let $listRemaining;
		if(allThemes) {
			$listRemaining = (
				<div className={`c-result c-result--remaining`}>
					{listRemaining}
				</div>
			)
		}


		if(this.state.isLoading) {
			
			return (
				<div className="page result-loading-wrap">
					<div className="result-loading">
						<ResultAnimation handleComplete={this.loadingComplete.bind(this)} />
					</div>
				</div>
			)
		}

		return (
			<div className="page">
				
                <div className="container">
					<div className={`o-content`}>
						<h1 className={`c-main-title c-main-title--alt c-main-title--result`}>Uitslag</h1>
						<h2 className="c-title-sub">Ontdek met welke partijen (en 
							<span 
								onClick={this.openPopup.bind(this, {
									title: 'Politieke jongerenorganisaties',
									body: <StaticContent />
								})}
								className="newsletterLink underline"
							>
								Politieke Jongerenorganisaties
							</span>) je de meeste raakvlakken hebt.</h2>
						<div className={`c-intro c-intro--result`}>Klik op een onderwerp voor een toelichting per partij</div>
						<div className={`c-result`}>
							{$listChosen}
						</div>

						{ $listRemaining }
						<div className="c-result-flex-buttons">
							{ $showAll }
							{ $hideAll }
						</div>
						<div className={`o-section`}>
							<Chart />
						</div>
						<div className={`o-section with-visual`}>
							<h2 className={`c-title-sub`}>Woensdag 17 maart 2021 zijn de Tweede Kamerverkiezingen. En jouw stem telt! Ga dus stemmen en beslis mee over jouw toekomst!</h2>
							<p>Meer doen met politiek? Word lid van een politieke jongerenorganisatie! Lees hier meer over
							<span 
								onClick={this.openPopup.bind(this, {
									title: 'Politieke jongerenorganisaties',
									body: <StaticContent />
								})}
								className="newsletterLink underline"
							>politieke jongerenorganisaties</span>.</p>
						</div>
					</div>
					{$popup}
				</div>

				<Footer/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		quiz: state.quiz
	}
}
  
function mapDispatchToProps(dispatch) {
	return {
	  actions: bindActionCreators({ finishTest, setHeaderLoading, setHeaderVisibility }, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Result)
