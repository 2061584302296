import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setHeaderVisibility } from '../actions/questions';
import HomeAnimation from "../lottie/HomeAnimation.js";
import Popup from '../components/Popup'
import StaticContent from '../components/StaticContent'
import Footer from '../components/Footer';
class Page extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
			popup: {
                open: false,
                loading: false,
				content: {}
			}
        }
    }
    componentDidMount() {

        var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
        if(isIE) {
            document.body.classList.add('old-browser')
        }

        this.props.actions.setHeaderVisibility(false);

    }

    handleClick() {
        document.getElementsByClassName('pagewrap')[0].classList.add('activo')
        const { province, settings } = this.props.quiz;
        setTimeout(() => {
			if(settings.hasOwnProperty('has_province_page') && !settings.has_province_page) {
                this.props.history.push(`/kiezen/${province.slug}/${province.options[0].slug}`)
            } else {
                this.props.history.push('/kiezen')
            }
		}, 1050)
        
    }

    closePopup() {
		this.setState({
			popup: {
				open: false,
				content: {}
			}
		})
	}

	openPopup(content, e) {
		this.setState({
			popup: {
				open: true,
                content: {
                    title: 'Politieke jongerenorganisaties',
                    body: <StaticContent />
                }
			}
		})
	}
 
    render() {
        const { popup } = this.state;
        const $popup = popup.open ? <Popup content={popup} close={this.closePopup.bind(this)} /> : false;
        return (
            
            <div className="pagewrap c-home-wrap">
                <div className="container">
                    <div className="page">
                        <div className="c-home">
                            <div>
                                <h1 className="c-main-title c-home__title" ref={(title) => this.title = title}>
                                Jongeren<br/>StemNogWijzer
                                </h1>
                                <div className="c-home__intro" ref={(intro) => this.intro = intro}>
                                    <p>Op 17 maart zijn de Tweede Kamerverkiezingen. Deze verkiezingen gaan over jouw toekomst! Het is dus belangrijk dat alle jongeren hun stem laten horen. Acht <span onClick={this.openPopup.bind(this)} className="underline">politieke jongerenorganisaties</span> hebben daarom samen een jongeren kieswijzer gemaakt. Een stemhulp voor en door jongeren dus. Gebruik deze kieswijzer om te ontdekken welke partij het beste bij jou past! </p>
                                </div>
                            </div>
                            <div className="c-home__loader" ref={(loader) => this.loader = loader}>
                                <HomeAnimation/>
                            </div>
                        </div>
                        <div className="c-btn-holder c-btn-holder--home">
                            <button className={`c-btn c-btn-home`} onClick={this.handleClick} ref={(button) => this.button = button}>
                                <span>Start</span>
                            </button>
                        </div>
                    </div>
                    {$popup}
                </div>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      index: state.index,
      quiz: state.quiz
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility  }, dispatch)
    }
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page))