import React, { Component } from 'react'

export default class StaticContent extends Component {

    render() {
        return (
            <div>
                <div>
                    <p>Een politieke jongerenorganisatie (PJO) is een vereniging voor jongeren die verbonden is aan een bepaalde politieke partij (de moederpartij). Vaak gaat een PJO wel onafhankelijk te werk. PJO’s vertegenwoordigen jongeren binnen en buiten de moederpartij en zorgen dat hun stem gehoord wordt. Iedereen tussen de 12 en 31 jaar kan lid worden van een PJO. Bij een PJO kan je veel leren over politiek en nieuwe vrienden maken met dezelfde politieke idealen als jij. PJO’s organiseren trainingen, debatten, en gezellige activiteiten. Vaak kan je zelf actief worden bij jouw lokale afdeling, werkgroepen en commissies. </p>
                    <p>Wil je meer weten over wat een bepaalde PJO allemaal doet? Klik dan door naar de website van de PJO’s die deze stemhulp hebben gemaakt:</p>
                </div>
                <div className="partners partners--popup">
                <ul className="partners__list">
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://jongedemocraten.nl/">
                            <img src="/media/images/partners/jonge-democraten.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://jovd.nl/">
                            <img src="/media/images/partners/jovd.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://jboppositie.nl/">
                            <img src="/media/images/partners/oppositie.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://cdja.nl/">
                            <img src="/media/images/partners/cdja.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.pinkpolitiek.nl/">
                            <img src="/media/images/partners/pink.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://js.nl/">
                            <img src="/media/images/partners/jonge-socialisten.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.perspectief.nu/nl/">
                            <img src="/media/images/partners/perspectief.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://dwars.org/">
                            <img src="/media/images/partners/dwars.svg" alt=""/>
                        </a>
                    </li>
                </ul>
                </div>
            </div>
        )
    }
}