import React, { Component } from 'react'

export default class FaqItem extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false
        }
    }

    handleClick() {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        });
    }

    render() {
        const { question, children } = this.props;
        const { isOpen } = this.state;
        return (
            <div className={`c-faq-item`}>
                <h2 className={`c-faq__title ${isOpen ? 'is-active' : ''}`} onClick={this.handleClick.bind(this)} >{question}</h2>
                <div className={`c-faq-item__answer ${isOpen ? 'is-active' : ''}`}>{children}</div>
            </div>
        )
    }
}