import React, { Component } from 'react'

export default class StaticContentAbout extends Component {

    render() {
        return (
            <div>
                <p>Jongeren gaan relatief minder vaak naar de stembus. En jongeren die de eerste keer geen gebruik maken van hun stemrecht, hebben een grotere kans om ook op latere leeftijd hun stem niet uit te brengen. Als politieke jongerenorganisaties vinden we het belangrijk dat alle jongeren gehoord worden. We hopen dan ook zoveel mogelijk jongeren te interesseren voor politiek.</p>   
                <p>Dankzij de informatie uit deze stemhulp leren we meer over de politieke voorkeuren van verschillende type jongeren. Deze inzichten zijn heel waardevol voor het bereiken van onze doelen.</p>
                <p>Jouw gegevens zijn gekoppeld aan de door jouw ingevulde antwoorden, maar worden volledig anoniem opgeslagen. Aan het einde van deze stemhulp kan je jouw e-mailadres achterlaten. Jouw e-mailadres is niet gekoppeld aan de gegevens die jij invult op de ‘Over jou’-pagina of aan jouw antwoorden in de stemhulp.</p>
                <p>Wil je jouw gegevens liever niet invullen? Klik dan op ‘volgende’.</p>
            </div>
        )
    }
}