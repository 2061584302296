import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { SIGN_UP_USER_FOR_NEWSLETTER } from '../actions/questions';

function validateEmail(email) {
	// eslint-disable-next-line
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

class Newsletter extends Component {

	constructor() {
		super();
		this.state = {
			validationError: null,
			email: null,
			subscribed: false,
			agreed: false
		}

		this.handleSubmission = this.handleSubmission.bind(this);
	}

	handleSubmission() {
		this.setState({
			validationError: null,
			subscribed: null
		}, () => {
			if (validateEmail(this.state.email)) {
				//SUBMIT
				this.props.actions.SIGN_UP_USER_FOR_NEWSLETTER(this.state.email).then(r => {
					this.setState({
						email: null,
						subscribed: r.message
					})
				}).catch(r => {
					this.setState({
						validationError: r,
						subscribed: null
					})
				})
			} else {
				this.setState({
					validationError: `Dit is geen geldig e-mailadres`,
					subscribed: null
				})
			}
		})

	}

	render() {
		
		return ( 
			<div className="footer-subscribe-wrapper">
				<div className="footer-subscribe">
					<h2>Wil jij meer weten over hoe jij meer kan doen met politiek?</h2> 
					<p>Laat hier je e-mailadres achter en ontvang meer informatie!</p> 

						
						{
							this.state.subscribed && ( 

								<div className="footer-subscribe-success"> 
									<svg xmlns="http://www.w3.org/2000/svg" width="352.62" height="352.62" viewBox="0 0 352.62 352.62">
										<path
											d="M337.222 22.952c-15.912-8.568-33.66 7.956-44.064 17.748-23.867 23.256-44.063 50.184-66.708 74.664-25.092 26.928-48.348 53.856-74.052 80.173-14.688 14.688-30.6 30.6-40.392 48.96-22.032-21.421-41.004-44.677-65.484-63.648C28.774 167.385-.602 157.593.01 190.029c1.224 42.229 38.556 87.517 66.096 116.28 11.628 12.24 26.928 25.092 44.676 25.704 21.42 1.224 43.452-24.48 56.304-38.556 22.645-24.48 41.005-52.021 61.812-77.112 26.928-33.048 54.468-65.485 80.784-99.145 16.524-20.808 68.544-72.217 27.54-94.248zM26.937 187.581c-.612 0-1.224 0-2.448.611-2.448-.611-4.284-1.224-6.732-2.448 1.836-1.224 4.896-.612 9.18 1.837z" />
									</svg>
									<span>{ this.state.subscribed }</span>
								</div>
							)
						} 

						{
							!this.state.subscribed && (
								<>
									<div className={this.state.validationError ? 'has-error' : ''}>
										{
											this.state.validationError && ( 
												<span className="footer-subscribe-error"> {
													this.state.validationError
												} </span>
											)
										} 
										<div className="email-input">						
										<input 
											
											type="email" 
											placeholder="E-mailadres"
											defaultValue={this.state.email}
											onChange = {
												(e) => this.setState({
													email: e.currentTarget.value,
													validationError: null
												})
											}
										/>
				
										<button disabled={!this.state.agreed} onClick={ this.handleSubmission }>
											<span></span>
										</button> 
										</div>
									</div>
									<div className="input-check">
										<input id="privacy-agreement" type="checkbox" onChange={() => this.setState({agreed: !this.state.agreed})} />
										<label htmlFor="privacy-agreement">Ik ga akkoord met het <NavLink className={`newsletterLink`} to={`/privacy`}> privacy statement</NavLink></label> 
									</div> 

									
								</>
							)
						}
					
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({
			SIGN_UP_USER_FOR_NEWSLETTER
		}, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter)