import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import anime from 'animejs';
import {ReactComponent as Logo } from './Logo.svg';
import BackButton from '../components/BackButton';
import Progress from "../lottie/Progress";

const DURATION = 200;
const STAGGER = 25;

class Header extends React.Component {

    componentDidMount() {
        this.tl = anime.timeline({
            loop: 1,
            duration: DURATION,
            autoplay: false,
        }).add({
            targets: '#header-logo path',
            opacity: [0.1, 1],
            easing: 'linear',
            delay: anime.stagger(STAGGER)
        }).add({
            targets: '#header-logo path',
            opacity: [1, 0.1],
            easing: 'linear',
            delay: anime.stagger(STAGGER)
        });
    }

    componentDidUpdate() {
        if(this.props.quiz.loading) {
            this.tl.play();
        }
    }

    render() {
        let next = this.props.quiz.loading
        return (
            <CSSTransition in={this.props.visible} timeout={500} classNames="header-status">
                <header className="animated-header header">
                    <div className="container">
                        <div className="header__inner">
                            <BackButton />
                            <a className="c-logo" href="/">
                                <Logo />
                            </a>
                        </div>
                        <div className="header__visual">
                            <Progress toNext={next}/>
                        </div>
                    </div>
                </header>
            </CSSTransition>
        )
    }
}

const mapStateToProps = state => {
    return {
        quiz: state.quiz
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);