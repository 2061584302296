import React, { Component } from 'react'

export default class Partners extends Component {

    render() {
        return (
            <div className="partners">
                <ul className="partners__list">
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://jongedemocraten.nl/">
                            <img src="/media/images/partners/jonge-democraten.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://jovd.nl/">
                            <img src="/media/images/partners/jovd.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://jboppositie.nl/">
                            <img src="/media/images/partners/oppositie.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://cdja.nl/">
                            <img src="/media/images/partners/cdja.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.pinkpolitiek.nl/">
                            <img src="/media/images/partners/pink.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://js.nl/">
                            <img src="/media/images/partners/jonge-socialisten.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.perspectief.nu/nl/">
                            <img src="/media/images/partners/perspectief.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://dwars.org/">
                            <img src="/media/images/partners/dwars.svg" alt=""/>
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}