import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'; 
import { setThemes, setHeaderLoading } from '../actions/questions'
// import { TimelineMax, TweenMax } from 'gsap';
import Button from '../components/Button'
import CheckAnimation from "../lottie/Check.js"

const MAX_SELECTIONS = 3;

class Themes extends Component {

	componentDidMount() {
		this.props.actions.setThemes([]);	
		this.props.actions.setHeaderLoading(false);
	}

	change(item, e) {
		let { themes } = this.props.quiz;

		let currState = themes;
		let entryIndex = currState.indexOf(item.id);
		if(entryIndex !== -1) {
			//remove
			currState.splice(entryIndex, 1);
		} else {
			//add
			currState.push(item.id)
		}
		const button = document.getElementsByClassName('c-btn--fixed')[0]
		if(button) {
			if(themes.length >= 3) {
				button.classList.add('activo')
			} else if(themes.length <= 3) {
				button.classList.remove('activo')
			}
		}
		//Update redux
		this.props.actions.setThemes(currState);
	}

	submit() {
		const { settings } = this.props.quiz;
		document.getElementsByClassName('page')[0].classList.add('activo')
		this.props.actions.setHeaderLoading(true);
		setTimeout(() => {
			
			if(settings.has_about_page) {
				this.props.history.push(`/uw-gegevens`);
			} else {
				this.props.history.push(`/resultaat`);
			}
		}, 1050)
	}

	render() {
		const { list, province } = this.props.quiz;
		let { themes } = this.props.quiz;

		// if(themes.length >= MAX_SELECTIONS) {
		// 	const button = document.getElementsByClassName('c-btn--fixed')[0].classList.add('activo')
		// 	if(button) {
		// 		button.classList.add('activo')
		// 	}
		// }
		
		const $themes = 
			list.filter((item, i) => province.id === item.id)[0].options
			.map((item, i) => {
				
				let disabled = false;
				if(themes.length === MAX_SELECTIONS && !themes.includes(item.id)) {
					disabled = true;
				}
				const isAnswer = themes.includes(item.id);
				return(
					<div className={`c-group__item c-checkbox`} key={`theme-${item.id}`}>
						<input disabled={disabled} onChange={this.change.bind(this, item)} id={`theme-${item.id}`} type="checkbox" />
						<label htmlFor={`theme-${item.id}`}>
							<span className="toggle">
								<CheckAnimation isAnswered={isAnswer} className={`checker-${item.id}`} ref={this.checkBox} />
							</span>
							{item.title}
						</label>
					</div>
				)
			})

		return (
			<div className="page">
				<div className="container">
					<div className="select-theme-container">
						<h1 ref={(title) => this.title = title} className={`c-main-title c-main-title--alt`}>Deze 3 onderwerpen vind ik het belangrijkst</h1>
						<p className={`c-description`} ref={(heavyTheme) => this.heavyTheme = heavyTheme}>De door jou ingevulde onderwerpen wegen zwaarder mee in jouw resultaat</p>
						<div ref={(items) => this.items = items} className={`c-group`}>
							{$themes}
						</div>
						<div className="c-btn-holder" ref={(pag) => this.pagination = pag }>
							<div className={`c-pagination`}>
								<Button subClass={`c-btn--fixed c-btn--themes`} title={`Volgende`} onClick={this.submit.bind(this)} />
								{
									province.slug === 'utrecht' &&
									(
										<div>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}>*De PVV doet niet mee aan de Kieswijzer voor de provincie Utrecht</span>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}><br/>*Forum voor Democratie doet niet mee aan de Kieswijzer</span>
										</div>
									)
								}
								{
									province.slug === 'noord-holland' &&
									(
										<div>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}>*De Ouderenpartij NH doet niet mee aan de Kieswijzer voor de provincie Noord-Holland</span>
											<span className={`c-description`} ref={(noParty) => this.noParty = noParty}><br/>*Forum voor Democratie doet niet mee aan de Kieswijzer</span>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
  return {
    quiz: state.quiz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setThemes, setHeaderLoading }, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Themes))