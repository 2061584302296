import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FaqItem from '../components/FaqItem';
import { setHeaderVisibility } from '../actions/questions'

class Faq extends Component {
    
    componentDidMount() {
        this.props.actions.setHeaderVisibility(true);
    }

    render() {
        return (
            <div className="page">
                <div className="container">
                    <div className={`o-content o-content--textpage`}>
                        <h1 className="c-main-title">
                            <span>Veelgestelde</span>
                            <span> vragen</span>
                        </h1>
                        <p>Deze kieswijzer is ontwikkeld voor en door jongeren voor de Tweede Kamerverkiezingen op 17 maart 2021.</p>

                        
                        <div className={`c-faq`}>
                            <FaqItem question={`Door wie is de stemhulp ontwikkeld?`}>
                                <p>Deze stemhulp is ontwikkeld op initiatief van van acht politieke jongerenorganisaties (PJO’s): DWARS (GroenLinkse jongeren), Jonge Democraten, PINK!, Jongerenorganisatie voor Vrijheid en Democratie (JOVD), Jonge Socialisten in de PvdA, CDJA, PerspectieF en Jongerenbeweging OPPOSITIE. Deze PJO’s zijn gelieerd aan respectievelijk GroenLinks, D66, Partij voor de Dieren, VVD, PvdA, CDA, ChristenUnie en DENK. De stemhulp is tot stand gekomen in samenwerking met Het Nieuwe Kiezen. Zij ontwikkelde het innovatieve concept voor kieswijzers waarbij geen gebruik wordt gemaakt van stellingen (eens/oneens), maar van uiteenlopende oplossingsrichtingen per onderwerp. De techniek en vormgeving van deze kieswijzer zijn ontwikkeld door communicatiebureau Just.</p>
                            </FaqItem>

                            <FaqItem question={`Waarom een speciale stemhulp voor jongeren? `}>
                                <p>In de politiek worden keuzes gemaakt die iedereen raken, nu en in de toekomst. De opkomst tijdens verkiezingen is onder jongeren altijd relatief laag. Daarom hebben acht politieke jongerenorganisaties van over het hele politieke spectrum de handen ineen geslagen om samen een stemhulp speciaal voor jongeren te ontwikkelen. In deze stemhulp komen verschillende thema’s aan bod, die voor jongeren in het bijzonder belangrijk zijn. We vinden het allemaal belangrijk dat alle jongeren gehoord worden in de politiek. We hopen dat meer jongeren dankzij de stemhulp Jongeren StemNogWijzer naar de stembus gaan om een weloverwogen stem uit te brengen.</p>
                            </FaqItem>
                            
                            <FaqItem question={`Wat maakt deze kieswijzer anders dan traditionele stemhulpen?`}>
                                <p>Een traditionele stemhulp bestaat uit een reeks stellingen waarmee je het eens of oneens bent. Eens of oneens levert echter een te zwart-wit beeld op. In veel gevallen doet dat geen recht aan wat je echt vindt. In deze vernieuwde stemhulp kies je bij ieder thema uit vijf uiteenlopende oplossingen voor een probleem. Daarmee is de kans een stuk groter dat jouw smaak er wel tussen zit. Op deze wijze krijg je een genuanceerder beeld van welke partij het beste bij jou past en stem je nóg wijzer. Daarbij krijg je bij de uitslag niet alleen te zien welke partij in het algemeen het best bij je past, maar kun je ook per thema zien hoe de verschillende partijen zich tot elkaar verhouden.</p>
                            </FaqItem>
                            
                            <FaqItem question={`Hoe is deze stemhulp tot stand gekomen?`}>
                                <p>Voor deze stemhulp zijn de betrokken politieke jongerenorganisaties onderling tot een aantal thema’s gekomen die door jongeren belangrijk worden gevonden, zoals onderwijs, klimaat en seksuele gezondheid. Vervolgens hebben we de verkiezingsprogramma’s van politieke partijen geraadpleegd om per thema vijf “smaken” te ontwikkelen. De politieke partijen zijn echter niet direct betrokken geweest bij de totstandkoming van deze stemhulp.</p>
                            </FaqItem>
                            
                            <FaqItem question={`Waarom kan ik maar voor één van de vijf antwoordopties kiezen?`}>
                                <p>Het concept is zo ingericht dat je steeds 1 antwoordoptie moet aanvinken. De vraag is namelijk wat de béste oplossing is. Oftewel waar de prioriteit moet liggen. Dit sluit andere oplossingen dus niet uit, maar geeft wel aan waar de (politieke) focus moet liggen. Het doel daarbij is om te laten zien dat er nuances zijn in de praktijk. Eens/oneens-stellingen doen namelijk geen recht aan de complexiteit van de realiteit. De vraag is dus niet óf we een maatschappelijk probleem moeten aanpakken, maar hoé we dat moeten doen.</p>
                            </FaqItem>

                            <FaqItem question={`Waarom ontbreken sommige politieke partijen?`}>
                                <p>In deze stemhulp vind je de moederpartijen van de initiatiefnemende politieke jongerenorganisaties. Ook hebben we contact gezocht met de overige politieke jongerenorganisaties en de politieke partijen die geen eigen jongerenorganisatie hebben, maar die momenteel wel in de Tweede Kamer zitten. De partijen die mee wilden werken, hebben we de thema’s en antwoordopties voorgelegd. Partijen die momenteel niet in de Tweede Kamer zitten zijn bij voorbaat niet meegenomen in ons proces.</p>
                                <p>Ook zijn niet alle politieke jongerenorganisaties betrokken bij dit project. Alleen de politieke jongerenorganisaties met een moederpartij in de Tweede Kamer zijn benaderd. Deze politieke jongerenorganisaties hebben allemaal een eigen keus gemaakt in het wel of niet deelnemen aan dit project.</p>
                            </FaqItem>

                            
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      quiz: state.quiz
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ setHeaderVisibility }, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Faq)