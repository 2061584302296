import React, { Component } from 'react'

export default class PartnersAlt extends Component {

    render() {
        return (
            <div className="partners">
                <ul className="partners__list">
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://doemee.d66.nl/wordlidjd/">
                            <img src="/media/images/partners/jonge-democraten.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://aanmelden.jovd.nl/">
                            <img src="/media/images/partners/jovd.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://jboppositie.nl/word-lid/">
                            <img src="/media/images/partners/oppositie.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://forms-cdja.nl/aanmelden/">
                            <img src="/media/images/partners/cdja.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.pinkpolitiek.nl/word_lid/">
                            <img src="/media/images/partners/pink.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://js.nl/word-lid-van-de-jonge-socialisten/">
                            <img src="/media/images/partners/jonge-socialisten.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.perspectief.nu/nl/lidworden">
                            <img src="/media/images/partners/perspectief.svg" alt=""/>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://dwars.org/word-actief/">
                            <img src="/media/images/partners/dwars.svg" alt=""/>
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}