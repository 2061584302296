import React, { Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { toSlug } from '../utils';

class Chart extends Component {

    constructor() {
		super();
		this.state = {
			allResults: false
		}
		this.showAll = this.showAll.bind(this)
		this.hideAll = this.hideAll.bind(this)
    }
    
    showAll() {
        document.body.classList.add('show-all-results')
        this.setState({
            allResults: true
        });
    }

    hideAll() {
        document.body.classList.remove('show-all-results')
        this.setState({
            allResults: false
        });
    }

    render() {

        const { province, themes } = this.props.quiz;
        let parties;
        let totalPoints = 0;
        let pointsPerParty = [];
        const $showAll = !this.state.allResults ? <button className={`c-btn-add`} onClick={this.showAll}>Toon alle partijen</button> : false
        const $hideAll = this.state.allResults ? <button className={`c-btn-add c-btn-less`} onClick={this.hideAll}>Verberg overige partijen</button> : false
        
        totalPoints += ( province.options.length + themes.length);

        province.options.forEach((question, i) => {
            if(question.answer) {
                parties = question.answer.result;
                parties.forEach( ( p ) => {
                    if(!pointsPerParty[p.title]) {
                        pointsPerParty[p.title] = 0;
                    }
                    if( themes.includes(question.id) ) {
                        pointsPerParty[p.title] += 2;
                    }else {
                        pointsPerParty[p.title] += 1;
                    }
                });
            }
        })
        
        //Rebuild array
        let build = [];
        Object.keys( pointsPerParty).forEach((p, i) => {
            build.push({
                'title': p,
                'qty': pointsPerParty[p],
                'percentage': (pointsPerParty[p] / totalPoints) * 100 
            })
        })

        //Sort DESCENDING
        build.sort((a, b) => b.qty - a.qty)       
    
        return (
            <div className="c-chart-wrapper">
                <table className="c-chart">
                    <tbody>
                        {  
                            build.map((item, i) => {
                                let percentage = item.percentage.toFixed(2)
                                return (
                                    <tr key={`chart-row-${i}`} className="c-chart-row">
                                        <td className="c-chart__title">{item.title}</td>
                                        <td className="c-chart__percentage-label">{percentage}%</td>
                                        <td className={`c-chart__percentage c-chart__percentage--${toSlug(item.title)}`}>
                                            <div style={{ width: `${percentage}%` }}></div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                { $showAll }
                { $hideAll }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        quiz: state.quiz
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({  }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chart)