import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom';
import Newsletter from '../containers/newsletter';
import Popup from '../components/Popup'
import Partners from './Partners'
import PartnersAlt from './PartnersAlt'
import StaticContent from './StaticContent'

const SHOW_NEWSLETTER_ON = [
    // '/',
    '/resultaat'
]
const SHOW_DIFF_LINKS_ON = [
    '/resultaat'
]


class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
			popup: {
                open: false,
                loading: false,
				content: {}
			}
        }
    }
    closePopup() {
		this.setState({
			popup: {
				open: false,
				content: {}
			}
		})
	}

	openPopup(content, e) {
		this.setState({
			popup: {
				open: true,
                content: {
                    title: 'Politieke jongerenorganisaties',
                    body: <StaticContent />
                }
			}
		})
	}

    render() {
        const { popup } = this.state;
        const $popup = popup.open ? <Popup content={popup} close={this.closePopup.bind(this)} /> : false;
        const { pathname } = this.props.location;
        return (
            <div className="c-footer" id="footer">
                
                <div className="container">
                    {
                        SHOW_NEWSLETTER_ON.includes(pathname) && (
                            <Newsletter />
                        )
                    }
                </div>
                
                <div className="container container--footer">
                    <div className="c-footer__inner">
                        <div className="c-footer__col">
                            <svg width="48px" height="54px" viewBox="0 0 48 54" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="potloodje" transform="translate(25.809820, 27.107383) rotate(63.000000) translate(-25.809820, -27.107383) translate(5.570150, -3.892617)">
                                        <polygon id="Path-18" fill="#FFFFFF" points="9.32044157e-12 18.0446497 6.55862452 0 24.4306641 9.96557466 22.0940426 20.4443982 9.37738491 24.4334553"></polygon>
                                        <polygon id="Path-17" fill="#EC79B6" points="4.3317917e-12 18.0446497 15.2566084 57.1015983 25.5088425 61.7030514 35.5324621 58.1467825 39.9923055 47.4540511 24.4306641 9.96557466 20.1859247 17.1017244 12.998235 15.1927309 9.31854248 20.8510646"></polygon>
                                        <polygon id="Path-19" fill="#EC79B6" points="6.55862452 1.54561259e-14 3.63557245 8.01987495 14.0443672 4.1538614"></polygon>
                                    </g>
                                </g>
                            </svg>
                            <div>
                                <p>Deze Kieswijzer is ontwikkeld i.s.m. <a href="https://hetnieuwekiezen.nl/" target="_blank" rel="noopener noreferrer">Het Nieuwe Kiezen</a> en <span onClick={this.openPopup.bind(this)} className="underline">Politieke Jongerenorganisaties</span>.</p> 
                                <div className="meta">
                                    <NavLink className="c-meta-link" to={`/veelgestelde-vragen`}>Veelgestelde vragen</NavLink>
                                    <NavLink className="c-meta-link" to={`/privacy`}>Privacy</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="c-footer__col">
                            {
                                !SHOW_DIFF_LINKS_ON.includes(pathname) && (
                                    <Partners />
                                )
                            }
                            {
                                SHOW_DIFF_LINKS_ON.includes(pathname) && (
                                    <PartnersAlt />
                                )
                            }
                        </div>
                    </div>
                </div>
                {$popup}
			</div>
		)
    }
}

export default withRouter(Footer);