import React, { Component } from 'react'

export default class Button extends Component {

    handleClick() {
        if(typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
        return;
    }

    render() {
        const {title} = this.props;
        return <button onClick={this.handleClick.bind(this)} className={`c-btn ${this.props.subClass}`}><span>{title}</span></button>
    }
}