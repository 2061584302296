import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'; 
import { setProvince } from '../actions/questions'
// import { TimelineMax, TweenMax } from 'gsap';
import BackButton from '../components/BackButton'


class Provinces extends Component {


	change(item, e) {
		this.props.actions.setProvince(item);
		this.props.history.push(`/kiezen/${item.slug}/${item.options[0].slug}`)
	}



	render() {
		const { list } = this.props.quiz
		const $provinces = list.map((item, id) => {
			return (
				<div key={item.slug} className={`c-group__item c-radio`}>
					<input onChange={this.change.bind(this, item)} id={`q-${item.slug}`} type="radio" name="province" />
					<label htmlFor={`q-${item.slug}`}>
							<span className="toggle"></span>
							{item.title}
					</label>
				</div>
			)
		})

		return (
			<div className="page">
				<h1 ref={ (title) => this.title = title } className={`c-main-title c-main-title--alt`}>Ik stem in deze<br/>provincie</h1>
				<div ref={ (items) => this.items = items } className={`c-group`}>
					{$provinces}
				</div>
				<div className="c-btn-holder">
					<BackButton />
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
  return {
    quiz: state.quiz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setProvince }, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Provinces))