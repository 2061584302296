import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

//Import all reducers
import rootReducer from './reducers'


import { createBrowserHistory } from 'history'
export const history = createBrowserHistory()


const logger = createLogger();
const initialState = {}
const enhancers = []
const middleware = [
  thunk,
]


if (process.env.NODE_ENV === `development`) {
  middleware.push(logger)
  
  const devToolsExtension = window.devToolsExtension
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }

}


// middleware.push(routerMiddleware(history))

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
)

  
export default store;
  