import React from 'react'
import Lottie from 'react-lottie';
import animationData from './Result.json'

export default class ResultAnimation extends React.Component {

    render() {

        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            },
        };

        const EVENTS = [
            {
                eventName: 'complete',
                callback: () => {
                    this.props.handleComplete();
                }
            }
        ];

        return (
                <div>
                    <Lottie options={defaultOptions} eventListeners={EVENTS} />
                </div>
            )
        }
}