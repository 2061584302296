import React, { Component } from 'react';
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Header from '../components/Header';
import Index from '../pages/index';
import Quiz from '../pages/quiz';
import Result from '../pages/result';
import Faq from '../pages/faq';
import Privacy from '../pages/privacy';
import UserFields from '../pages/userFields';
import Themes from '../containers/themes';
import { fetchData } from '../actions/questions'

import { history } from '../store'

class Page extends Component {

  componentDidMount() {
    this.props.actions.fetchData();
  }

  render() {

    const { list } = this.props.quiz;
    
    if(!list.length) {
      return false;
    }

    const renderRouter = ({ location }) => {
      const currentKey = location.pathname.split('/') || '/'

      return (
        <TransitionGroup component="main">
          <CSSTransition key={currentKey} timeout={550} classNames="fade" appear>
            <Switch location={location}>
              <Route exact path="/" component={Index} />
              <Route exact path="/kiezen" component={Quiz} />
              <Route path="/kiezen/:province/:question" component={Quiz} />
              <Route path="/kiezen/:province" component={Quiz} />
              <Route exact path="/resultaat" component={Result} />
              <Route exact path="/veelgestelde-vragen" component={Faq} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/uw-gegevens" component={UserFields} />
              <Route exact path="/themas" component={Themes} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      )
    }

    return (
      <ConnectedRouter history={history}>
        <div>
          <Header visible={this.props.quiz.headerVisible} />
            <div className="main-content">
              <Route render={renderRouter}/>
            </div>
        </div>
      </ConnectedRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    quiz: state.quiz
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchData }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
