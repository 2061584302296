import React, { Component } from 'react'
import { TimelineMax, TweenMax, Expo } from 'gsap';

export default class Popup extends Component {

    constructor() {
        super();
        this.onKey = this.onKey.bind(this)
        this.onClick = this.onClick.bind(this)
        this.close = this.close.bind(this)
    }

    componentDidMount() {
        
        let tl = new TimelineMax({
            onComplete: () => {
                document.addEventListener("keydown", this.onKey);
                document.addEventListener("click", this.onClick);
            }
        });
        tl.add(TweenMax.fromTo(this.popup, .5, { autoAlpha: 0 }, { autoAlpha: 1 }))
        tl.add(TweenMax.fromTo(this.popupInner, .5, { y: 100 }, { y: 0, ease: Expo.easeOut }), '-=.5')

    }
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKey);
        document.removeEventListener("click", this.onClick);
    }

    onKey(e) {
        if(e.keyCode === 27) {
            this.close()
        }
    }

    onClick(e) {
        if(e.target.classList.contains('c-popup')) {
            this.close();
		}
    }

    close() {
        const animProps = {
            from: { autoAlpha: 1 },
            to: { autoAlpha: 0 }
        }
        let tl = new TimelineMax({
            onComplete: this.props.close
        });

        tl.add(TweenMax.fromTo(this.popup, .5, animProps.from , animProps.to))
        tl.add(TweenMax.fromTo(this.popupInner, .5, { y: 0 }, { y: 100, ease: Expo.easeOut }), '-=.5')
    }

    render() {
        const { content } = this.props.content;
        const { title, poster, body } = content;
        let $poster, $video;
        if(poster) {
            $poster = (
                <figure>
                    <img src={`${poster}`} alt={`${title}`} />
                </figure>
            )
        }
        if(content.video && content.video.length) {
            let videoID = content.video.split('v=')[1];
            if(videoID) {
                let ampersandPosition = videoID.indexOf('&');
                if(ampersandPosition !== -1) {
                    videoID = videoID.substring(0, ampersandPosition);
                }
                $video = (
                    <div className="o-media">
                        <iframe src={`https://www.youtube-nocookie.com/embed/${videoID}?enablejsapi=1&rel=0&modestbranding=1&showinfo=0`} title="youtube"></iframe>
                    </div>
                )
            }
        }

        

        setTimeout(()=> {
            var desc = document.querySelectorAll(".c-popup__desc"); 
            if(desc[0]) {
                var links = desc[0].getElementsByTagName('a');
                for( var i = 0; i < links.length; i++ )
                {
                    links[i].setAttribute("target", "_blank");
                }
            }
        }, 300)

        return (
            <div ref={(popup) => this.popup = popup } className={`c-popup`}>
                <div ref={(popupInner) => this.popupInner = popupInner } className={`c-popup__inner`}>
                    
                    <h1 className={`c-popup-title`}>{title}</h1>
                    
                    {
                        !$video && 
                            $poster
                    }
                    
                    {$video}

                    { 
                        !React.isValidElement(body) &&
                            <div className={`c-popup__desc`} dangerouslySetInnerHTML={{__html: body}}></div>
                    }

                    {
                        React.isValidElement(body) &&
                            <div className={`c-popup__desc`}>
                                {body}
                            </div>
                    }
                    
                    <button className={`c-popup__close`} onClick={this.close}></button>
                </div>
            </div>
        )
    }
}