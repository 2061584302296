import React from 'react'
import Lottie from 'react-lottie';
import animationData from './Home.json'

export default class homeAnimation extends React.Component {

  render() {

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
 
    return <Lottie options={defaultOptions}/>
 
  }
}