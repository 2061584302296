import React from 'react'
import Lottie from 'react-lottie';
import animationData from "./Progress.json";
export default class Progress extends React.Component {

    render() {

        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
                <div>
                <Lottie options={defaultOptions} isStopped={!this.props.toNext} />
                </div>
            )
        }
}
